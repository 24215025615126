<template>
  <div class="edu-frame">
    <div v-if="active == 0">
      <van-search background="#1989fa" v-model="condition" show-action placeholder="请输入搜索关键词" @search="onSearch">
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
      <div style="height: calc(100vh - 54px - 50px);overflow: auto;background-color: #eeeeee">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(banner, index) in banners" :key="index" @click="toActivity(banner)">
            <img class="swipe-img" :src="banner.imgUrl">
          </van-swipe-item>
        </van-swipe>

        <van-tabs :active="typeActive" @change="changeType">
          <van-tab v-for="(type, index) in activitysettingtypes"  :key="'type_' + index" :title="type.typeName">
            <van-list
                class="activity-list"
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
            >
              <div v-for="item in list" :key="item.activitySettingMasterID" class="activity-item" @click="toBaodu(item)">
                <div class="item-title">
                  <div class="item-title-line"></div>
                  {{item.activityName}}
                </div>
                <div class="item-desc">
                  <div class="item-desc-title">推荐星级</div>
                  <div class="item-desc-detail">
                    <van-icon name="star" v-for="star in item.star" :key="star"/>
                    <van-icon name="star-o" v-for="star2 in (5 - item.star)" :key="star2+'none'"/>
                    <div style="color: gray; margin: 0px 5px;">|</div>
                    <div style="color: white;background-color: rgb(33, 182, 138); font-size: 13px;padding: 0px 3px;">新</div></div>
                </div>
                <div class="item-desc">
                  <div class="item-desc-title">承办方</div>
                  <div class="item-desc-detail">{{ item.organisers }}</div>
                </div>
                <div class="item-desc">
                  <div class="item-desc-title">活动时间</div>
                  <div class="item-desc-detail">{{item.actBeginTime | filterDate}} - {{item.actEndTime | filterDate}}</div>
                </div>
                <div class="item-desc" style="justify-content: flex-end;margin: 0px auto;">
                  <van-button size="small" type="info" @click="toBaodu(item)" style="width: 60px;font-size: 16px;line-height: 34px">报名</van-button>
                </div>
              </div>
            </van-list>
          </van-tab>
        </van-tabs>


      </div>
    </div>
    <div v-if="active == 1">
      <div style="height: calc(100vh - 50px);">
        <activitys-records></activitys-records>
      </div>
    </div>
    <!--<div class="activity-list"></div>-->
    <van-tabbar v-model="active" @change="onChange">
      <van-tabbar-item icon="new">活动列表</van-tabbar-item>
      <van-tabbar-item icon="records">我的活动</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import {Search, Swipe, SwipeItem, List, Icon, Button, Tabbar, TabbarItem, Tab, Tabs} from "vant";
import ActivityApi from "@/api/ActivityApi";
import XEUtils from "xe-utils";
import wx from "weixin-js-sdk";
import ActivitysRecords from "@/Activity/ActivitysRecords.vue";
export default {
  name: 'activitys',
  components: {
    VanSearch: Search,
    VanSwipe: Swipe,
    VanSwipeItem: SwipeItem,
    VanList: List,
    VanIcon: Icon,
    VanButton: Button,
    VanTabbar: Tabbar,
    VanTabbarItem: TabbarItem,
    VanTab: Tab, VanTabs: Tabs,
    ActivitysRecords
  },
  filters: {
    filterDate(date) {
      return date != null && date != '' ? XEUtils.toDateString(date, 'yyyy-MM-dd HH:mm') : ''
    }
  },
  data() {
    return {
      condition: '',
      loading: false,
      finished: false,
      pageInfo: { page: 1, pageSize: 15, condition: {} },
      list: [],
      banners: [],
      active: 0,
      typeActive: '0',
      activitysettingtypes: []
    }
  },
  methods: {
    onChange(index) {
      console.log('index', index)
      sessionStorage.setItem('activitytab', index)
    },
    /*onLoad() {
      console.log('onLoad.')
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },*/
    onSearch() {
      this.list = []
      this.onLoad()
    },
    toBaodu(item) {
      this.$router.push({ name: 'actinfo', query: { activitySettingMasterId: item.activitySettingMasterID } })
    },
    changeType(e) {
      console.log('this.typeActive == ', this.activitysettingtypes[e].typeId)
      this.pageInfo.page = 1
      this.pageInfo.condition.typeId = this.activitysettingtypes[e].typeId
      this.finished = false
      this.list = []
      this.onLoad()
    },
    onLoad() {
      this.loading = true
      ActivityApi.getActivitys(this.pageInfo).then(response => {
        this.loading = false
        // console.log(response)
        for(var i = 0; i < response.res.list.length; i++) {
          this.list.push(response.res.list[i])
        }
        if (response.res.isLastPage == 1) {
          this.finished = true
        } else {
          this.pageInfo.page = this.pageInfo.page+1
        }
      }).catch(() => { this.finished = true;this.loading = false; })
    },
    getActivityBanners() {
      ActivityApi.getActivityBanners().then(response => {
        this.banners = response.res
      })
    },
    toActivity(banner) {
      if (banner.href == null || banner.href == '') {
        return
      }
      location.href = '#/' + banner.href
    },
    setShareConfig() {
      // alert('测试分享。')
      var stitle = '活动报名'

      wx.updateAppMessageShareData({
        title: stitle, // 分享标题
        desc: '省妇儿活动中心教学管理系统', // 分享描述
        link: 'https://edu.gdfezx.com/qxapi/edu/wechat/oauthforact?page=activitys', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'http://edumgr.gdfezx.com/img/logo.73f12c01.png', // 分享图标
        success: function () {
          // 设置成功
          // alert('分享给朋友成功。')
        },
        cancel: function() {
          // alert('cancel');
        },
        fail: function(res) {
          // alert('fail'+JSON.stringify(res));
          console.log(res)
        }
      })

      wx.updateTimelineShareData({
        title: stitle, // 分享标题
        link: 'https://edu.gdfezx.com/qxapi/edu/wechat/oauthforact?page=activitys',// window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'http://edumgr.gdfezx.com/img/logo.73f12c01.png', // 分享图标
        success: function () {
          // 设置成功
          // alert('分享到朋友圈成功。')
        },
        cancel: function() {
          // alert('cancel');
        },
        fail: function(res) {
          // alert('fail'+JSON.stringify(res));
          console.log(res)
        }
      })
    },
    getActivitySettingTypes() {
      var tablePage = { page: 1, pageSize: 1000, total: 0, condition: {} }
      ActivityApi.getActivitySettingTypes(tablePage).then(response => {
        response.res.list.unshift({ typeId: null, typeName: '全部' })
        this.activitysettingtypes = response.res.list
      })
    }
  },
  mounted() {
    this.getActivityBanners()
    this.setShareConfig()
    this.getActivitySettingTypes()
    var openId = this.$route.query.openid
    // alert(openId)
    if (openId != null && openId != '') {
      sessionStorage.setItem('openId', openId)
    }

    if (sessionStorage.getItem('activitytab') != null) {
      this.active = parseInt(sessionStorage.getItem('activitytab'))
    }
  }
}
</script>
<style scoped>
.van-search__action {
  background-color: #1989fa;
  color: white;
}

.my-swipe {
  /* height: 120px; */
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  /* line-height: 120px; */
  text-align: center;/*
  background-color: #39a9ed;*/
}
.swipe-img {
  width: 100%;
}
.activity-list {
  width: 100%;
   height: calc(100vh - 120px - 54px - 45px);
  background-color: #eeeeee;
  overflow: auto;
}

.activity-item {
  width: calc(100% - 20px);
  background-color: white;
  margin: 10px 0px;
  padding: 10px 10px;
}

.item-title {
  display: flex;
  align-items: center;
  font-size: 19px;
  color: black;
}
.item-title-line {
  width: 3px;height: 20px;margin-right: 8px;
}
.item-desc {
  display: flex;
  font-size: 15px;
  margin: 4px 0px;
}
.item-desc-title {
  width: 80px;

}
.item-desc-detail {
  display: flex;
  color: #f54646;
  align-items: center;
}
</style>
