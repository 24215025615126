<!--20240529_1-->
<template>
  <div class="edu-frame">
    <van-search
        v-model="condition"
        show-action
        placeholder="请输入活动名称搜索"
        @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div style="height: calc(100% - 54px - 50px);">

      <van-list
          class="activity-list"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >


        <div v-for="item in list" :key="item.activitySettingMasterID" class="activity-item" style="width: calc(100% - 20px);background-color: white;margin: 10px 0px;padding: 10px 10px;" @click="goTo(item)">

          <div class="item-title">
            <div class="item-title-line"></div>
            {{ item.activityName }}
          </div>

          <div class="item-desc">
            <div class="item-desc-title">活动单号</div>
            <div class="item-desc-detail">{{ 'HD' + item.activityBuyMasterId}}</div>
          </div>
          <div class="item-desc">
            <div class="item-desc-title">购买时间</div>
            <div class="item-desc-detail">{{item.buyTime}}</div>
          </div>
          <div class="item-desc">
            <div style="display: flex;flex: 1;">
              <div class="item-desc-title">活动费用</div>
              <div class="item-desc-detail">￥{{item.totalMoney}}</div>
            </div>
            <div style="display: flex;flex: 1;">
              <div class="item-desc-title">支付状态</div>
              <div class="item-desc-detail" :style="item.isPay == 1 ? 'color: mediumseagreen' : 'color:red'">{{item.isPay == 1 ? '已支付' : '未支付'}}</div>
            </div>
          </div>

          <div class="item-desc">
            <div class="item-desc-title">支付时间</div>
            <div class="item-desc-detail">{{item.payTime}}</div>
          </div>

        </div>



      </van-list>

    </div>

  </div>
</template>
<script>
import {List, Search} from "vant";
import ActivityApi from "@/api/ActivityApi";
import Tools from "@/api/Tools";
export default {
  components: {
    VanSearch: Search,
    VanList: List,
  },
  data() {
    return {
      condition: '',
      loading: false,
      finished: false,
      pageInfo: { page: 1, pageSize: 30, condition: {} },
      list: [],
    }
  },
  methods: {
    onSearch(){
      this.list = []
      this.pageInfo.page = 1
      this.onLoad()
    },
    onLoad() {
      this.finished = true
      this.loading = true
      var openId = Tools.getSession('openId')
      this.pageInfo.condition.wxOpenId = openId // 'omZtgwIkptsQSafFQ4i2l1nmz8x0' // openId
      this.pageInfo.condition.activityName = this.condition
      if (openId == null) {
        this.finished = true
        return
      }
      ActivityApi.getActivityBuyMasters(this.pageInfo).then(response => {
        this.loading = false
        console.log(response)
        for(var i = 0; i < response.res.list.length; i++) {
          this.list.push(response.res.list[i])
        }
        if (response.res.isLastPage == 1) {
          this.finished = true
        } else {
          this.pageInfo.page = this.pageInfo.page+1
        }
      }).catch(() => { this.loading = false })
    },
    goTo(item) {
      this.$router.push({ name: 'activitysrecorddetail', query: { activityBuyMasterId: item.activityBuyMasterId } })
    }
  }
}
</script>
<style scoped>
.activity-list {
  width: 100%;
  height: 100%;
  /* height: calc(100vh - 120px - 54px); */
  background-color: #eeeeee;
  overflow: auto;
}

.activity-item {
  width: calc(100% - 20px);
  background-color: white;
  margin: 10px 0px;
  padding: 10px 10px;
}
.item-title {
  display: flex;
  align-items: center;
  font-size: 17px;
  color: black;
}
.item-title-line {
  width: 3px;height: 20px;margin-right: 8px;
  background-color: red;;
}
.item-desc {
  display: flex;
  font-size: 15px;
  margin: 4px 0px;
}
.item-desc-title {
  width: 80px;

}
.item-desc-detail {
  display: flex;
  /*color: #f54646;*/
  align-items: center;
}
</style>
